<template>
  <v-container class="mt-3">
    <div class="title" v-if="!calling && !noConnection">
      {{ $t("voip.makeCall") }}
    </div>
    <div class="title text-center mt-3" v-else-if="calling">
      {{ $t("voip.calling") }}
    </div>
    <div class="title text-center mt-3" v-else-if="noConnection">
      {{ $t("voip.noConnection") }}
    </div>
    <div class="title text-center mt-3" v-if="calling || noConnection">
      {{ phonenumber }}
    </div>
    <phonenumber-input
      class="mt-3 text-left"
      v-model="phonenumber"
      v-if="!calling && !noConnection"
      data-v-step="voip_0"
    ></phonenumber-input>
    <v-row justify="center" class="mt-3" no-gutters>
      <v-col v-if="!calling && !noConnection" cols="12" class="text-center">
        <v-btn large fab color="green" class="white--text" @click="dial()">
          <v-icon>mdi-phone</v-icon>
        </v-btn>
      </v-col>

      <v-col cols="12" v-if="calling || noConnection" class="text-center">
        <v-btn
          fab
          large
          color="red"
          dark
          @click="(calling = false), (phonenumber = '')"
        >
          <v-icon>mdi-phone-hangup</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-container>
      <template v-if="!calling && !noConnection">
        <h3 class="mt-3">{{ $t("voip.quickcallbtn") }}</h3>
        <v-divider></v-divider>
      </template>
      <v-row data-v-step="voip_1">
        <v-col
          v-for="(item, index) in buttons"
          :key="index"
          v-if="!calling && !noConnection"
          cols="6"
        >
          <v-btn
            block
            :color="item.color"
            rounded
            dark
            :x-large="!$vuetify.breakpoint.xs"
            :x-small="$vuetify.breakpoint.xs"
            min-height="40"
            @click="(calling = true), (phonenumber = item.text), dial()"
          >
            <v-icon left>{{ item.icon }}</v-icon>
            {{ item.text }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import PhonenumberInput from "@/components/PhonenumberInput";

export default {
  data() {
    return {
      phonenumber: "",
      calling: false,
      noConnection: false,
    };
  },
  components: {
    PhonenumberInput,
  },
  methods: {
    dial() {
      if (this.phonenumber.length > 0) {
        this.calling = true;
      }
      this.$ga.event({
        eventCategory: "Call specific number",
        eventAction: "call",
        eventLabel: `Called phone custom phone number!`,
        eventValue: 1,
      });
    },
  },
  computed: {
    buttons() {
      return [
        {
          color: "blue",
          text: this.$t("voip.reception"),
          icon: "mdi-bell-ring",
        },
        {
          color: "orange",
          text: this.$t("voip.service"),
          icon: "mdi-room-service",
        },
        {
          color: "indigo",
          text: this.$t("voip.housekeeping"),
          icon: "mdi-hotel",
        },
        {
          color: "#228B22",
          text: this.$t("voip.business"),
          icon: "mdi-domain",
        },
        {
          color: "#FF7F50",
          text: this.$t("voip.spa"),
          icon: "mdi-spa",
        },
        {
          color: "#B8860B",
          text: this.$t("voip.concierge"),
          icon: "mdi-face-agent",
        },
      ];
    },
  },
  watch: {
    calling(val) {
      if (val) {
        setTimeout(() => {
          this.noConnection = true;
        }, 700);
      }
    },
    noConnection(val) {
      if (val) {
        this.calling = false;
        setTimeout(() => {
          this.noConnection = false;
        }, 1000);
      }
    },
  },
};
</script>

<template>
  <v-row no-gutters>
    <v-col class="shrink">
      <v-autocomplete
        style="min-width: 120px"
        hide-details
        v-model="country"
        :items="countryList"
        :filter="customFilter"
        menu-props="auto"
        :label="$t('app.country')"
        prepend-inner-icon="mdi-map-marker"
        single-line
        return-object
        item-value="key"
        :search-input.sync="filterTerm"
      >
        <template v-slot:item="{ item }">
          <span class="mr-2">{{ item.native }}</span>
          <span>+{{ item.countryCallingCode }}</span>
        </template>
        <template v-slot:selection="{ item }">
          +
          <span>{{ item.countryCallingCode }}</span>
        </template>
      </v-autocomplete>
    </v-col>

    <v-col class="grow">
      <v-form ref="number" v-model="numberValid">
        <v-text-field
          :autofocus="country.key !== ''"
          required
          :rules="numberRules"
          :label="example"
          v-model="number"
          append-icon="mdi-phone"
        ></v-text-field>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import {
  parsePhoneNumberFromString,
  isSupportedCountry,
} from "libphonenumber-js";
import { getExampleNumber, getCountryCallingCode } from "libphonenumber-js";
import examples from "libphonenumber-js/examples.mobile.json";
import { countries } from "countries-list";
import { mapState } from "vuex";
import includes from "lodash/includes";

export default {
  data() {
    return {
      country: { key: "" },
      number: "",
      filterTerm: "",
      numberRules: [() => this.isValid || ""],
      numberValid: false,
    };
  },
  computed: {
    ...mapState({
      language: ({ language }) => language.language,
    }),
    ...mapState({
      locale: ({ i18n }) => i18n.locale,
    }),
    countryList() {
      return [].concat(
        Object.keys(countries)
          .map((key) => {
            return { ...countries[key], key };
          })
          .filter((item) => isSupportedCountry(item.key))
          .map((item) => {
            return {
              ...item,
              countryCallingCode: getCountryCallingCode(item.key),
            };
          })
      );
    },
    countryBySelectedLanguage() {
      let foundCountries = [];
      this.countryList.forEach((item) => {
        // pre select only when there is one language in the country
        if (item.languages.length == 1) {
          let found = item.languages.find((key) => key == this.locale);
          if (found) {
            foundCountries.push(item);
          }
        }
      });
      if (foundCountries.length === 1) {
        return foundCountries[0];
      } else {
        return this.country;
      }
    },
    example() {
      try {
        return new parsePhoneNumberFromString(
          getExampleNumber(this.country.key, examples).number,
          this.country.key
        ).format("NATIONAL");
      } catch (e) {
        return "";
      }
    },
    internationalNumber() {
      try {
        const phoneNumber = parsePhoneNumberFromString(
          this.number,
          this.country.key
        );
        return phoneNumber.number;
      } catch (e) {
        return "";
      }
    },
    isValid() {
      try {
        const phoneNumber = parsePhoneNumberFromString(
          this.number,
          this.country.key
        );
        return phoneNumber.isValid();
      } catch (e) {
        return false;
      }
    },
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      if (this.country.key === "") {
        this.country = this.countryBySelectedLanguage;
      }
    },
    customFilter(item, queryText) {
      return (
        includes(item.native.toLowerCase(), queryText.toLowerCase()) ||
        includes(item.name.toLowerCase(), queryText.toLowerCase()) ||
        includes(
          `+${item.countryCallingCode.toLowerCase()}`,
          queryText.toLowerCase()
        )
      );
    },
  },
  watch: {
    number() {
      this.$refs.number.validate();
    },
    country() {
      this.$refs.number.validate();
    },
    internationalNumber(val) {
      if (this.numberValid) {
        this.$emit("input", val);
      } else {
        this.$emit("input", "");
      }
    },
    numberValid(val) {
      if (val) {
        this.$emit("input", this.internationalNumber);
      } else {
        this.$emit("input", "");
      }
    },
    locale() {
      this.initData();
    },
  },
};
</script>
